import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Step,
  StepButton,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from '../../../../components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '../../../../components/Modal/Modal';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { Delete, FileUpload, Visibility } from '@mui/icons-material';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Swal from 'sweetalert2';
import { isEditable } from '../../../../functions';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function Pescado() {
  const { id } = useParams();
  const history = useHistory();
  const inputRef = useRef();
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [evaluacionLote, setEvaluacionLote] = useState(null);
  const [header, setHeader] = useState({
    Folio: '',
    ProveedorID: '',
    Proveedor: '',
    Fecha: '',
  });
  const [detalleRecepcion, setDetalleRecepcion] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [recepcion, setRecepcion] = useState({
    userID: sessionStorage.getItem('user_id'),
    detailID: 0,
    RecepcionID: id,
    TinaNum: '',
    PesoBru: 0,
    PesoNeto: 0,
    Tara: 0,
    EspecieID: null,
    TallaID: null,
    Temperatura: 0,
    Histamina: '',
    Observaciones: '',
  });
  const [muestra, setMuestra] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluacionlotepesid: 0,  // Agrego Caro. 27-Nov
    recepcionid: id,
    muestranum: 0,
    temperatura: 0,
    peso: 0,
    aspectoojos: '',
    colorojos: '',
    aspectobran: '',
    olorbran: '',
    colorbran: '',
    aspectopiel: '',
    olorpiel: '',
    colorpiel: '',
  });
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [especies, setEspecies] = useState([]);
  const [tallas, setTallas] = useState([]);
  const [dictamenes, setDictamentes] = useState([]);
  const [totales, setTotales] = useState([]);
  //   AAA1: '',
  //   AAA2: '',
  //   AAA3: '',
  //   AAA5: '',
  //   AAA10: '',
  //   AAA60: '',
  //   BBCO1: '',
  //   BBCO2: '',
  //   BBCO3: '',
  //   BBCO5: '',
  //   BONITA1: '',
  //   BONITA2: '',
  //   BONITA3: '',
  //   PANZA: '',
  //   RECHAZO: '',
  // });
  const [evaluacion, setEvaluacion] = useState({
    detailid: 0,
    proveedorid: null,
    barco: '',
    tinarec: 0,
    especieId: null,
    cantidadrec: 0,
    certificadocal: 0,
    cumpletemprec: 0,
    dictamenid: null,
    dictamen: null,
  });
  const [evaluacionDet, setEvaluacionDet] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsEvaluacion, setSelectedRowsEvaluacion] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [totalesTinas, setTotalesTinas] = useState({
    recepcionid: id,
    tinasrec: 0,
    cantidadrec: 0,
  });
  const [totalesEspecies, setTotalesEspecies] = useState([]);

  const columnsRecepcion = [
    {
      label: 'ID',
      name: 'ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Tina', name: 'TinaNum' },
    { label: 'Peso Bruto', name: 'PesoBruto' },
    { label: 'Tara', name: 'Tara' },
    { label: 'Peso Neto', name: 'PesoNeto' },
    { label: 'Descripción', name: 'Especie' },
    { label: 'Temperatura', name: 'Temperatura' },
    { label: 'Histamina', name: 'Histamina' },
    { label: 'Observaciones', name: 'Observacion' },
    { label: 'Causa Aceptación', name: 'CausaAcep' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => handleEditRecepcion(tableMeta.rowData[0])}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsEvaluacion = [
    {
      label: 'ID',
      name: 'ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Muestra Num', name: 'MuestraNum' },
    { label: 'Temperatura', name: 'Temperatura' },
    { label: 'Peso', name: 'Peso' },
    { label: 'Aspecto Ojos', name: 'AspectoOjos' },
    { label: 'Color Ojos', name: 'ColorOjos' },
    { label: 'Aspecto Branquias', name: 'AspectoBran' },
    { label: 'Olor Branquias', name: 'OlorBran' },
    { label: 'Color Branquias', name: 'ColorBran' },
    { label: 'Aspecto Piel', name: 'AspectoPiel' },
    { label: 'Color Piel', name: 'ColorPiel' },
    { label: 'Olor Piel', name: 'OlorPiel' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => handleEditEvaluacion(tableMeta.rowData[0])}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsFiles = [
    { label: 'Archivo', name: 'nomarchivo' },
    {
      label: 'Ver',
      name: 'id',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} target='_blank'>
              <Fab color='primary' size='small'>
                <Visibility />
              </Fab>
            </a>
          );
        },
      },
    },
  ];

  const handleEditRecepcion = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRecepcion.find((x) => x.ID === id);
    setRecepcion({
      ...recepcion,
      detailID: detalle.ID,
      TinaNum: detalle.TinaNum || '',
      PesoBru: detalle.PesoBruto || '',
      PesoNeto: detalle.PesoNeto || '',
      Tara: detalle.Tara || '',
      EspecieID: { id: detalle.EspecieID, name: detalle.Especie } || '',
      TallaID: { id: detalle.TallaID, name: detalle.Talla } || '',
      Temperatura: detalle.Temperatura || '',
      Histamina: detalle.Histamina || '',
      Observaciones: detalle.Observacion || '',
      CausaAcep: detalle.CausaAcep || '',
    });
  };

  const handleEditEvaluacion = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = evaluacionDet.find((x) => x.ID === id);
    setMuestra({
      ...muestra,
      userid: sessionStorage.getItem('user_id'),
      detailid: detalle.ID || '',
      evaluacionlotepes: detalle.evaluacionlotepes || '',  //Agregado por Caro. 27-Nov
      recepcionid: detalle.RecepcionID || '',
      muestranum: detalle.MuestraNum || '',
      temperatura: detalle.Temperatura || '',
      peso: detalle.Peso || '',
      aspectoojos: detalle.AspectoOjos || '',
      colorojos: detalle.ColorOjos || '',
      aspectobran: detalle.AspectoBran || '',
      olorbran: detalle.OlorBran || '',
      colorbran: detalle.ColorBran || '',
      aspectopiel: detalle.AspectoPiel || '',
      olorpiel: detalle.ColorPiel || '',
      colorpiel: detalle.OlorPiel || '',
    });
  };

  const limpiarRecepcion = () => {
    setRecepcion({
      ...recepcion,
      detailID: 0,
      TinaNum: '',
      PesoBru: 0,
      PesoNeto: 0,
      Tara: 0,
      EspecieID: null,
      TallaID: null,
      Temperatura: 0,
      Histamina: '',
      Observaciones: '',
      CausaAcep: '',
    });
  };

  const limpiarMuestra = () => {
    setMuestra({
      ...muestra,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      recepcionid: id,
      muestranum: 0,
      temperatura: 0,
      peso: 0,
      aspectoojos: '',
      colorojos: '',
      aspectobran: '',
      olorbran: '',
      colorbran: '',
      aspectopiel: '',
      olorpiel: '',
      colorpiel: '',
    });
  };

  // eslint-disable-next-line
  const limpiarEvaluacion = () => {
    setEvaluacion({
      ...evaluacion,
      proveedorid: null,
      barco: '',
      tinarec: 0,
      especieId: null,
      cantidadrec: 0,
      certificadocal: 0,
      cumpletemprec: 0,
      dictamenid: null,
      dictamen: null,
    });
  };

  const getRecepcion = () => {
    setLoading(true);
    axios
      .get(`/rmp/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRecepcion(res.data.data.Detail);
        setTotales(res.data.data.Totales);
        const resEval = res.data.data.Evaluacion[0];
        if (res.data.data.Evaluacion.length > 0) {
          setEvaluacion({
            ...evaluacion,
            // ...res.data.data.Evaluacion[0],
            detailid: resEval.ID,
            barco: resEval.Barco,
            especieId: {
              id: resEval.EspecieID,
              name: resEval.Especie,
            },
            dictamenid: {
              id: resEval.DictamenID,
              name: resEval.Dictamen,
            },
            tinarec: resEval.TinaRec,
            cantidadrec: resEval.CantidadRec,
            certificadocal: resEval.CertificadoCal,
            cumpletemprec: resEval.CumpleTempRec,
          });
        }
        setEvaluacionLote(
          res.data.data.Evaluacion[0] ? res.data.data.Evaluacion[0].ID : null,
        );
        setMuestra({
          ...muestra,
          evaluacionlotepesid: res.data.data.Evaluacion[0]
            ? res.data.data.Evaluacion[0].ID
            : null,
        });
        setEvaluacionDet(res.data.data.EvaluaDetail);
        setArchivos(res.data?.data?.Files);
        getTotalesEspecies();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getTotalesTinas = () => {
    axios
      .get(`/rmp/pes/totalestinas/${id}`, {})
      .then((res) => {
        setTotalesTinas(res.data.data[0]);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getTotalesEspecies = () => {
    axios
      .get('/rmp/pes/relespecietalla', {})
      .then((res) => {
        setTotalesEspecies(res.data.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getEspecies = () => {
    axios
      .get('/catalogo/especies', {})
      .then((res) => {
        setEspecies(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTallas = () => {
    axios
      .get('/catalogo/tallas', {})
      .then((res) => {
        setTallas(res.data.data);
      })
      .catch(() => {});
  };

  const getDictamenes = () => {
    axios
      .get('/catalogo/dictamen')
      .then((res) => {
        setDictamentes(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const especiesProps = {
    options: especies.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const tallasProps = {
    options: tallas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const dictamenesProps = {
    options: dictamenes.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const handleSubmitRecepcion = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...recepcion,
      EspecieID: recepcion.EspecieID.id,
      TallaID: recepcion.TallaID.id,
    };

    axios
      .post('/rmp/pes/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRecepcion();
        getTotalesTinas();
        limpiarRecepcion();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitHeader = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...evaluacion,
      especieid: evaluacion.especieId.id,
      dictamenid: evaluacion.dictamenid.id,
      userid: sessionStorage.getItem('user_id'),
      recepcionid: id,
      proveedorid: header.ProveedorID,
    };

    axios
      .post('/rmp/evalot', body)
      .then((res) => {
        setEvaluacionLote(res.data.id);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRecepcion();
        getTotalesTinas();
        limpiarRecepcion();
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleDeleteRecepcion = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/rmp/delete/pes/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleDeleteEvaluacion = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/rmp/delete/evalot/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleSubmitEvaluacion = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post('/rmp/evalot/detail', muestra)
      .then((res) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRecepcion();
        getDictamenes();
        getTotalesTinas();
        getTotalesEspecies();
        limpiarMuestra();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/rmp/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/registro-materia-prima');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDrag(false);
    if (!e.dataTransfer.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.dataTransfer.files[i].name,
        type: e.dataTransfer.files[i].type,
        file: e.dataTransfer.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.target.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        file: e.target.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleDeleteFile = (id) => {
    const aux = files;
    for (let i = 0; i < aux.length; i++) {
      if (aux[i].id == id) {
        aux.splice(i, 1);
      }
    }
    setFiles([...aux]);
  };

  const handleSubmitArchivos = async () => {
    if (files.length > 0) {
      setLoading(true);
      let err = false;
      let message = '';

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('files', files[i].file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        await axios
          .post(`/upload/pes/${id}`, formData, config)
          .then((res) => {
            if (res.data.status === 'error') {
              err = true;
              message = res.data?.message;
            } else {
              err = false;
              setFiles(null);
            }
            getRecepcion();
            setLoading(false);
          })
          .catch((error) => {
            err = true;
            message = error.response?.data?.message;
            setLoading(false);
          });
      }

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se han guardado los archivos exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'No se han cargado archivos.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
  };

  useEffect(() => {
    getRecepcion();
    getEspecies();
    getTallas();
    getDictamenes();
    getTotalesTinas();
    getTotalesEspecies();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
              <Step key='recepcion'>
                <StepButton onClick={() => setActiveStep(1)}>
                  Recepción
                </StepButton>
              </Step>
              <Step key='evaluacion'>
                <StepButton onClick={() => setActiveStep(2)}>
                  Evaluación
                </StepButton>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={12}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.Folio || ''}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <TextField
                    label='Proveedor'
                    fullWidth
                    disabled
                    value={header.Proveedor || ''}
                  />
                </Grid>
                <Grid item lg={4} xs={12}>
                  <TextField
                    label='Fecha'
                    fullWidth
                    disabled
                    value={header.Fecha || ''}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {activeStep === 1 ? (
            <>
              <Grid item xs={12}>
                <Widget>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      mb: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Recepción de pescado
                    </Typography>
                    <Box sx={{ float: 'right' }}>
                      {!disabledDoc ? (
                        <Button
                          variant='contained'
                          onClick={() => {
                            limpiarRecepcion();
                            dispatch({ type: 'OPEN_INPUT_MODAL' });
                          }}
                        >
                          Nuevo
                        </Button>
                      ) : (
                        ''
                      )}
                      <Modal
                        open={state.toggleInputModal}
                        dispatch={dispatch}
                        handleSubmit={handleSubmitRecepcion}
                        disabledDoc={disabledDoc}
                        form={
                          <>
                            {/* {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )} */}
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='tina'
                                label='Tina'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    TinaNum: e.target.value,
                                  })
                                }
                                name='tina'
                                value={recepcion.TinaNum || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='pesoBruto'
                                label='Peso Bruto'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    PesoBru: e.target.value,
                                    PesoNeto:
                                      recepcion.Tara !== ''
                                        ? parseFloat(e.target.value) -
                                          parseFloat(recepcion.Tara)
                                        : 0,
                                  })
                                }
                                name='pesoBruto'
                                value={recepcion.PesoBru || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='tara'
                                label='Tara'
                                fullWidth
                                required
                                onChange={(e) => {
                                  setRecepcion({
                                    ...recepcion,
                                    Tara: e.target.value,
                                    PesoNeto:
                                      recepcion.PesoBru !== ''
                                        ? parseFloat(recepcion.PesoBru) -
                                          parseFloat(e.target.value)
                                        : 0,
                                  });
                                }}
                                name='tara'
                                value={recepcion.Tara || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                inputProps={{ type: 'number' }}
                                disabled
                                id='pesoNeto'
                                label='Peso Neto'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    PesoNeto: e.target.value,
                                  })
                                }
                                name='pesoNeto'
                                value={recepcion.PesoNeto || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='especies'
                                {...especiesProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Especie'
                                  />
                                )}
                                value={recepcion.EspecieID}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setRecepcion({
                                      ...recepcion,
                                      EspecieID: newValue,
                                    });
                                  } else {
                                    setRecepcion({
                                      ...recepcion,
                                      EspecieID: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='tallas'
                                {...tallasProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Talla'
                                  />
                                )}
                                value={recepcion.TallaID}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setRecepcion({
                                      ...recepcion,
                                      TallaID: newValue,
                                    });
                                  } else {
                                    setRecepcion({
                                      ...recepcion,
                                      TallaID: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='temperatura'
                                label='Temperatura'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    Temperatura: e.target.value,
                                  })
                                }
                                name='temperatura'
                                value={recepcion.Temperatura || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='histamina'
                                label='Histamina'
                                fullWidth
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    Histamina: e.target.value,
                                  })
                                }
                                name='histamina'
                                value={recepcion.Histamina || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                label='Observaciones'
                                fullWidth
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    Observaciones: e.target.value,
                                  })
                                }
                                name='observaciones'
                                value={recepcion.Observaciones || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='causaAceptacion'
                                label='Causa Aceptación'
                                fullWidth
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    CausaAcep: e.target.value,
                                  })
                                }
                                name='causaAceptacion'
                                value={recepcion.CausaAcep || ''}
                              />
                            </Grid>
                          </>
                        }
                      />
                    </Box>
                  </Box>
                  <MUIDataTable
                    data={detalleRecepcion}
                    columns={columnsRecepcion}
                    options={{
                      responsive: 'standard',
                      selectableRows: disabledDoc ? 'none' : 'multiple',
                      rowsSelected: selectedRows,
                      onRowSelectionChange: (
                        rowsSelectedData,
                        allRows,
                        rowsSelected,
                      ) => {
                        setSelectedRows(rowsSelected);
                      },
                      customToolbarSelect: (selectedRows) => (
                        <Tooltip title='Eliminar'>
                          <IconButton
                            onClick={async () => {
                              setLoading(true);
                              let response;
                              for (
                                let i = 0;
                                i < selectedRows.data.length;
                                i++
                              ) {
                                response = await handleDeleteRecepcion(
                                  detalleRecepcion[
                                    selectedRows.data[i].dataIndex
                                  ].ID,
                                );
                              }
                              getRecepcion();
                              setSelectedRows([]);
                              if (!response.err) {
                                Swal.fire({
                                  title: 'Éxito',
                                  text: '¡Se han eliminado los registros exitosamente!',
                                  icon: 'success',
                                  confirmButtonColor: '#196b86',
                                });
                              } else {
                                Swal.fire({
                                  title: 'Advertencia',
                                  text: response.message,
                                  icon: 'warning',
                                  confirmButtonColor: '#196b86',
                                });
                              }
                            }}
                            style={{
                              marginRight: '24px',
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget sx={{ mt: 2 }} title='Totales'>
                  <Grid container spacing={2}>
                    {totalesEspecies.map((especie) => (
                      <React.Fragment key={especie.Id}>
                        <Grid item lg={3} xs={6} key={especie.Id}>
                          <TextField
                            fullWidth
                            disabled
                            label={`${especie.especie} ${especie.talla}`}
                            // value={totales.AAA1}
                            value={
                              totales.find(
                                (x) =>
                                  x.Especie === especie.especie &&
                                  x.Talla === especie.talla,
                              )?.Totales || 0
                            }
                            // sx={{ mt: 1, mb: 1 }}
                          />
                        </Grid>
                      </React.Fragment>
                    ))}
                    {/* {especies.map((especie) => (
                      <React.Fragment key={especie.Id}>
                        <Grid item lg={3} xs={6} key={especie.Id}>
                          <Typography variant='h4' sx={{ mb: 1 }}>
                            {especie.Description}
                          </Typography>
                          {tallas.map((talla) => (
                            <TextField
                              key={talla.Id}
                              fullWidth
                              disabled
                              label={`${especie.Description} ${talla.Description}`}
                              // value={totales.AAA1}
                              value={
                                totales.find(
                                  (x) =>
                                    x.Especie === especie.Description &&
                                    x.Talla === talla.Description,
                                )?.Totales || 0
                              }
                              sx={{ mt: 1, mb: 1 }}
                            />
                          ))}
                        </Grid>
                      </React.Fragment>
                    ))} */}
                    {/* <Grid item lg={3} xs={6}>
                      <Typography variant='h4'>Pescado</Typography>
                      <TextField
                        fullWidth
                        disabled
                        label='Panza'
                        value={totales.PANZA}
                        sx={{ mt: 1.5, mb: 1.5 }}
                      />
                      <TextField
                        fullWidth
                        disabled
                        label='Rechazo'
                        value={totales.RECHAZO}
                      />
                    </Grid> */}
                  </Grid>
                </Widget>
              </Grid>
              {!disabledDoc ? (
                <Grid item xs={12}>
                  <Widget>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2,
                        mb: 2,
                        '& a': {
                          textDecoration: 'none',
                          color: '#fff',
                        },
                      }}
                    >
                      <Typography variant='h3' color='text' noWrap>
                        Evidencias
                      </Typography>
                      {!disabledDoc ? (
                        <Button
                          variant='contained'
                          onClick={() => {
                            // setEdit(false);
                            handleSubmitArchivos();
                          }}
                        >
                          Guardar Archivos
                        </Button>
                      ) : (
                        ''
                      )}
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Box sx={{ margin: '0 auto' }}>
                          <div
                            className={`dropzone ${drag ? 'drag' : ''}`}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragEnd}
                            onDrop={handleDrop}
                          >
                            <input
                              type='file'
                              onChange={(e) => handleUpload(e)}
                              hidden
                              multiple
                              ref={inputRef}
                            />
                            <Fab
                              color='primary'
                              variant='extended'
                              aria-label='upload'
                              size='small'
                              onClick={() => inputRef.current.click()}
                            >
                              <FileUpload /> Cargar Archivo
                            </Fab>
                            <Typography
                              sx={{ color: 'primary', fontSize: 12, mt: 1 }}
                            >
                              o arrastra los archivos
                            </Typography>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={2}>
                          {files?.map((file) => (
                            <Chip
                              key={file.name}
                              label={file.name}
                              onDelete={() => handleDeleteFile(file.id)}
                            ></Chip>
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Widget>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={12}>
                <Widget>
                  <Typography
                    variant='h3'
                    color='text'
                    noWrap
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Archivos
                  </Typography>
                  <MUIDataTable
                    title='Archivos'
                    data={archivos}
                    columns={columnsFiles}
                    options={{
                      responsive: 'standard',
                      selectableRows: 'none',
                    }}
                  />
                </Widget>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Widget>
                  <form onSubmit={handleSubmitHeader}>
                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          fullWidth
                          label='Barco'
                          value={evaluacion.barco}
                          onChange={(e) =>
                            setEvaluacion({
                              ...evaluacion,
                              barco: e.target.value,
                            })
                          }
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Autocomplete
                          disabled={disabledDoc}
                          fullWidth
                          id='especies'
                          {...especiesProps}
                          renderInput={(params) => (
                            <TextField required {...params} label='Especie' />
                          )}
                          value={evaluacion.especieId}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setEvaluacion({
                                ...evaluacion,
                                especieId: {
                                  id: newValue.id,
                                  name: newValue.name,
                                },
                              });
                            } else {
                              setRecepcion({ ...recepcion, especieId: null });
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <Autocomplete
                          disabled={disabledDoc}
                          fullWidth
                          id='dictamen'
                          {...dictamenesProps}
                          renderInput={(params) => (
                            <TextField required {...params} label='Dictamen' />
                          )}
                          value={evaluacion.dictamenid}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setEvaluacion({
                                ...evaluacion,
                                dictamenid: {
                                  id: newValue.id,
                                  name: newValue.name,
                                },
                              });
                            } else {
                              setEvaluacion({
                                ...evaluacion,
                                dictamenid: null,
                              });
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          label='Cantidad Recibida'
                          fullWidth
                          disabled
                          value={totalesTinas.cantidadrec || ''}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <TextField
                          label='Tinas Recibidas'
                          fullWidth
                          disabled
                          value={totalesTinas.tinasrec || ''}
                        />
                      </Grid>
                      {/* <Grid item lg={6} xs={12}>
                        <TextField
                          disabled
                          inputProps={{ type: 'number', min: 0 }}
                          fullWidth
                          label='Cantidad Recibida'
                          value={totalesTinas.cantidadrec}
                          // onChange={(e) =>
                          //   setEvaluacion({
                          //     ...evaluacion,
                          //     cantidadrec: e.target.value,
                          //   })
                          // }
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextField
                          disabled
                          inputProps={{ type: 'number', min: 0 }}
                          fullWidth
                          label='Tinas Recibidas'
                          value={totalesTinas.tinasrec}
                          // onChange={(e) =>
                          //   setEvaluacion({
                          //     ...evaluacion,
                          //     tinarec: e.target.value,
                          //   })
                          // }
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <Box sx={{ mt: 2, textAlign: 'center' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={disabledDoc}
                                checked={
                                  evaluacion.certificadocal === '1'
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    certificadocal: e.target.checked
                                      ? '1'
                                      : '0',
                                  })
                                }
                              />
                            }
                            label='Cumple con certificado de calidad'
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={disabledDoc}
                                checked={
                                  evaluacion.cumpletemprec === '1'
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    cumpletemprec: e.target.checked ? '1' : '0',
                                  });
                                }}
                              />
                            }
                            label='Cumple temperatura de recepción'
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Button
                            disabled={disabledDoc}
                            sx={{ float: 'right' }}
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </Widget>
              </Grid>
              {evaluacionLote ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Widget>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mt: 2,
                        mb: 2,
                        '& a': {
                          textDecoration: 'none',
                          color: '#fff',
                        },
                      }}
                    >
                      <Typography variant='h3' color='text' noWrap>
                        Muestra
                      </Typography>
                      <Box sx={{ float: 'right' }}>
                        {!disabledDoc ? (
                          <Button
                            variant='contained'
                            onClick={() => {
                              limpiarMuestra();
                              dispatch({ type: 'OPEN_INPUT_MODAL' });
                            }}
                          >
                            Nuevo
                          </Button>
                        ) : (
                          ''
                        )}
                        <Modal
                          open={state.toggleInputModal}
                          dispatch={dispatch}
                          handleSubmit={handleSubmitEvaluacion}
                          disabledDoc={disabledDoc}
                          form={
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id='temperatura'
                                  label='Temperatura'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      temperatura: e.target.value,
                                    })
                                  }
                                  name='temperatura'
                                  value={muestra.temperatura || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id='peso'
                                  label='Peso'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      peso: e.target.value,
                                    })
                                  }
                                  name='peso'
                                  value={muestra.peso || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='aspectoOjos'
                                  label='Aspecto Ojos'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      aspectoojos: e.target.value,
                                    })
                                  }
                                  name='aspectoOjos'
                                  value={muestra.aspectoojos || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='colorOjos'
                                  label='Color Ojos'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      colorojos: e.target.value,
                                    })
                                  }
                                  name='colorOjos'
                                  value={muestra.colorojos || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='aspectoBranquias'
                                  label='Aspecto Branquias'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      aspectobran: e.target.value,
                                    })
                                  }
                                  name='aspectoBranquias'
                                  value={muestra.aspectobran || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='olorBranquias'
                                  label='Olor Branquias'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      olorbran: e.target.value,
                                    })
                                  }
                                  name='olorBranquias'
                                  value={muestra.olorbran || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='colorBranquias'
                                  label='Color Branquias'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      colorbran: e.target.value,
                                    })
                                  }
                                  name='colorBranquias'
                                  value={muestra.colorbran || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='aspectoPiel'
                                  label='Aspecto Piel'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      aspectopiel: e.target.value,
                                    })
                                  }
                                  name='aspectoPiel'
                                  value={muestra.aspectopiel || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='colorPiel'
                                  label='Color Piel'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      colorpiel: e.target.value,
                                    })
                                  }
                                  name='colorPiel'
                                  value={muestra.colorpiel || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='olorPiel'
                                  label='Olor Piel'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setMuestra({
                                      ...muestra,
                                      olorpiel: e.target.value,
                                    })
                                  }
                                  name='olorPiel'
                                  value={muestra.olorpiel || ''}
                                />
                              </Grid>
                            </>
                          }
                        />
                      </Box>
                    </Box>
                    <MUIDataTable
                      data={evaluacionDet}
                      columns={columnsEvaluacion}
                      options={{
                        responsive: 'standard',
                        selectableRows: disabledDoc ? 'none' : 'multiple',
                        rowsSelected: selectedRowsEvaluacion,
                        onRowSelectionChange: (
                          rowsSelectedData,
                          allRows,
                          rowsSelected,
                        ) => {
                          setSelectedRowsEvaluacion(rowsSelected);
                        },
                        customToolbarSelect: (selectedRows) => (
                          <Tooltip title='Eliminar'>
                            <IconButton
                              onClick={async () => {
                                setLoading(true);
                                let response;
                                for (
                                  let i = 0;
                                  i < selectedRows.data.length;
                                  i++
                                ) {
                                  response = await handleDeleteEvaluacion(
                                    evaluacionDet[
                                      selectedRows.data[i].dataIndex
                                    ].ID,
                                  );
                                }
                                getRecepcion();
                                setSelectedRowsEvaluacion([]);
                                if (!response.err) {
                                  Swal.fire({
                                    title: 'Éxito',
                                    text: '¡Se han eliminado los registros exitosamente!',
                                    icon: 'success',
                                    confirmButtonColor: '#196b86',
                                  });
                                } else {
                                  Swal.fire({
                                    title: 'Advertencia',
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonColor: '#196b86',
                                  });
                                }
                              }}
                              style={{
                                marginRight: '24px',
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Widget>
                </Grid>
              ) : (
                ''
              )}
            </>
          )}
          <Paper
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
            elevation={4}
          >
            <Box sx={{ float: 'right' }}>
              {activeStep === 1 ? (
                <Button
                  variant='contained'
                  onClick={() => setActiveStep(2)}
                  endIcon={<SkipNextIcon />}
                >
                  Evaluación
                </Button>
              ) : (
                <Button
                  disabled={disabledDoc}
                  variant='contained'
                  onClick={handleEnd}
                  endIcon={<CheckCircleIcon />}
                >
                  Terminar
                </Button>
              )}
            </Box>
          </Paper>
        </Grid>
      )}
    </>
  );
}

export default Pescado;
